import { Component} from '@angular/core';
import { Desp3Service } from 'src/app/shared/services/desp3.service';
import { Desp5Service } from 'src/app/shared/services/desp5.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dash-desp3',
  templateUrl: './dash-desp3.component.html',
  styleUrls: ['./dash-desp3.component.css']
})
export class DashDesp3Component{
  excelData: any;
  mappedData: any[] = []; 
  selectedFile: File = null;

  constructor(private desp3Service: Desp3Service, private desp5Service: Desp5Service) {}

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsBinaryString(this.selectedFile)

    function excelDateToJSDate(serial) {
      var utc_days  = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;                                        
      var date_info = new Date(utc_value * 1000);
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
      var total_seconds = Math.floor(86400 * fractional_day);
      var seconds = total_seconds % 60;
      total_seconds -= seconds;
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      var date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      return date.toISOString();
   }

    fileReader.onload = (e) => {
      var workbook = XLSX.read(fileReader.result, { type: 'binary' });
      var sheetName = workbook.SheetNames;
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName[4]]); 

      sheetData.forEach((row: any) => {
        const valorCustoContab = row[" VALOR_CUSTO_CONTAB "].toString().replace(',', '.');
        const valorCustoContabFloat = parseFloat(valorCustoContab);

        const rowData = {
            coD_PROCESSO: row["COD_PROCESSO"],
            datA_CONHECIMENTO: excelDateToJSDate(row["DATA_CONHECIMENTO"]),
            tipO_CONHECIMENTO: row["TIPO_CONHECIMENTO"],
            coD_FORNECEDOR: row["COD_FORNECEDOR"],
            desC_FORNEC: row["DESC_FORNEC"],
            origem: row["ORIGEM"],
            unidade: row["UNIDADE"],
            pecas: row["PECAS"],
            fatura: row["FATURA"],
            fabricante: row["FABRICANTE"],
            qtdE_PECA: row["QTDE_PECA"],
            datA_INSERT: new Date().toISOString()
        };
        
        // Reorganize os atributos na ordem correta
        const dadosOrdenados = this.ordenarDados(rowData);
        
        this.mappedData.push(dadosOrdenados); // Adiciona o objeto mapeado a mappedData
      });
    }
  }

  onFileChangeDesp5(event: any) {
    this.selectedFile = event.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsBinaryString(this.selectedFile)

    function excelDateToJSDate(serial) {
      var utc_days  = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;                                        
      var date_info = new Date(utc_value * 1000);
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
      var total_seconds = Math.floor(86400 * fractional_day);
      var seconds = total_seconds % 60;
      total_seconds -= seconds;
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      var date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      return date.toISOString();
   }

    fileReader.onload = (e) => {
      var workbook = XLSX.read(fileReader.result, { type: 'binary' });
      var sheetName = workbook.SheetNames;
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName[2]]); 

      sheetData.forEach((row: any) => {
        const valorCustoContab = row[" VALOR_CUSTO_CONTAB "].toString().replace(',', '.');
        const valorCustoContabFloat = parseFloat(valorCustoContab);

        const rowData = {
            id: row["Index"],
            coD_PROCESSO: row["COD_PROCESSO"],
            datA_CONHECIMENTO: excelDateToJSDate(row["DATA_CONHECIMENTO"]),
            tipO_CONHECIMENTO: row["TIPO_CONHECIMENTO"],
            coD_FORNECEDOR: row["COD_FORNECEDOR"],
            desC_FORNEC: row["DESC_FORNEC"],
            transportador: row["TRANSPORTADOR"],
            origem: row["ORIGEM"],
            tipo: row["TIPO"],
            coD_MOEDA: row["COD_MOEDA"],
            credor: row["CREDOR"],
            valoR_CUSTO_CONTAB: isNaN(valorCustoContabFloat) ? undefined : valorCustoContabFloat,
            unidade: row["UNIDADE"],
            coD_CONTA_CONTABIL: row["COD_CONTA_CONTABIL"],
            centrO_CUSTO: row["CENTRO_CUSTO"],
            datA_CONTABIL: excelDateToJSDate(row["DATA_CONTABIL"]),
            modalidade: row["Modalidade"], // Campo ajustado
            datA_INSERT: new Date().toISOString()
        };
        
        // Reorganize os atributos na ordem correta
        const dadosOrdenados = this.ordenarDadosDesp5(rowData);
        
        this.mappedData.push(dadosOrdenados); // Adiciona o objeto mapeado a mappedData
      });
    }
  }
  
  ordenarDados(dados: any): any {
    const modeloOrdem = [
        "coD_PROCESSO",
        "datA_CONHECIMENTO",
        "tipO_CONHECIMENTO",
        "coD_FORNECEDOR",
        "desC_FORNEC",
        "origem",
        "unidade",
        "pecas",
        "fatura",
        "fabricante",
        "qtdE_PECA",
        "datA_INSERT"
    ];

    const dadosOrdenados: any = {};

    modeloOrdem.forEach((chave: string) => {
        if (dados.hasOwnProperty(chave)) {
            dadosOrdenados[chave] = dados[chave];
        }
    });

    return dadosOrdenados;
  }

  ordenarDadosDesp5(dados: any): any {
    const modeloOrdem = [
        "id",
        "coD_PROCESSO",
        "datA_CONHECIMENTO",
        "tipO_CONHECIMENTO",
        "coD_FORNECEDOR",
        "desC_FORNEC",
        "transportador",
        "origem",
        "tipo",
        "coD_MOEDA",
        "credor",
        "valoR_CUSTO_CONTAB",
        "unidade",
        "coD_CONTA_CONTABIL",
        "centrO_CUSTO",
        "datA_CONTABIL",
        "modalidade",
        "datA_INSERT"
    ];

    const dadosOrdenados: any = {};

    modeloOrdem.forEach((chave: string) => {
        if (dados.hasOwnProperty(chave)) {
            dadosOrdenados[chave] = dados[chave];
        }
    });

    return dadosOrdenados;
  }

  async uploadFile() {
    let formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    console.log(this.selectedFile);
  
    const batchSize = 100; // ajuste conforme necessário
    const batches = Math.ceil(this.mappedData.length / batchSize);
  
    for (let i = 0; i < batches; i++) {
      const batchData = this.mappedData.slice(i * batchSize, (i + 1) * batchSize);
     (await this.desp3Service.postDesp3List(batchData)).subscribe({
          next: (response: string) => {
            console.log("Upload realizado com sucesso para:", i);
            console.log("Resposta da API:", response);
          },
          error: (error: any) => {
            console.error("Erro no Upload para:", i, error);
          }
        });
      }
  }
  
  async uploadFileDesp5() {
      let formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      console.log(this.selectedFile);
    
      const batchSize = 100; // ajuste conforme necessário
      const batches = Math.ceil(this.mappedData.length / batchSize);
    
      for (let i = 0; i < batches; i++) {
        const batchData = this.mappedData.slice(i * batchSize, (i + 1) * batchSize);
       (await this.desp5Service.postDesp5List(batchData)).subscribe({
            next: (response: string) => {
              console.log("Upload realizado com sucesso para:", i);
              console.log("Resposta da API:", response);
            },
            error: (error: any) => {
              console.error("Erro no Upload para:", i, error);
            }
          });
        }
  }
}
