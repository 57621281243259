<div>
    <h1 class="tds-headline-01">Book Closing LOM</h1>
    <div style="width: 90%; margin-left: 2%;">
      <tds-divider orientation="horizontal"></tds-divider>
    </div>
</div>
<div>
    <form [formGroup]="dateForm" (ngSubmit)="onSubmit()">
        <label for="start">Data inicial:</label>
        <input id="start" type="date" formControlName="start">
      
        <label for="end">Data final:</label>
        <input id="end" type="date" formControlName="end">
      
        <button type="submit">Filtrar</button>
      </form>
      <div>
        <tds-table class="table" vertical-dividers="false" compact-design="false" responsive="true">
            <tds-table-header>
              <tds-header-cell cell-key="id" cell-value="Id"></tds-header-cell>
              <tds-header-cell cell-key="spec" cell-value="Spec"></tds-header-cell>
              <tds-header-cell cell-key="total" cell-value="Total"></tds-header-cell>
              <tds-header-cell cell-key="datA_INSERT" cell-value="Data Insert" text-align="right"></tds-header-cell>
            </tds-table-header>
            <tds-table-body>
              <tds-table-body-row *ngFor="let item of dadosTabela">
                <tds-body-cell [cell-value]="item.id" cell-key="id"></tds-body-cell>
                <tds-body-cell [cell-value]="item.spec" cell-key="spec"></tds-body-cell>
                <tds-body-cell [cell-value]="item.total" cell-key="total"></tds-body-cell>
                <tds-body-cell [cell-value]="item.datA_INSERT" cell-key="datA_INSERT"></tds-body-cell>
              </tds-table-body-row>
            </tds-table-body>
        </tds-table>
    </div>
    <div>
        <div>
            <table class="table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Spec</th>
                    <th>Total</th>
                    <th>Data Insert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dadosTabela">
                    <td>{{item.id}}</td>
                    <td>{{item.spec}}</td>
                    <td>{{item.total}}</td>
                    <td>{{item.datA_INSERT}}</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    
</div>


