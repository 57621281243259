import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from 'rxjs/operators'; // Importe o operador tap aqui
import { environment } from "src/environments/environment";
import { Login } from "../models/login";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public apiService: string = environment.urlSnape + '/api/Usuario';
    private loggedIn = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { }

    Authenticate(data: Login): Observable<any> {
        let url = this.apiService + '/login';
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(url, data, { headers: headers, responseType: 'text' }).pipe(
            tap(res => {
                if (res) {
                    this.loggedIn.next(true);
                    localStorage.setItem('token', res);
                }
            })
        );
    }
    renewToken(): Observable<any> {
    let url = this.apiService + '/login'; // Substitua pela URL correta
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, { headers: headers, responseType: 'text' }).pipe(
        tap(res => {
            if (res) {
                this.loggedIn.next(true);
                localStorage.setItem('token', res); // Armazene o novo token no localStorage
            }
        })
    );
}

    isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }
}
