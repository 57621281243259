import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { CalculosGeraisByDate, CalculosGeraisByDateResponse } from "../models/calculosGeraisByDate";
import { CalculosGeraisByDateSpec } from "../models/calculosGeraisByDateSpec";
import { CalculosGeraisBySpec } from "../models/calculosGeraisBySpec";
import { AllSpecNames } from "../models/allSpecNames";

@Injectable({
    providedIn: 'root'
})

export class CalculosGeraisService{
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public token$: Observable<string> = this.tokenSubject.asObservable();
  public apiService: string = environment.urlSnape + '/api/CalculosGerais/';

  constructor(private http: HttpClient) {
      this.initializeToken();
  }

  private initializeToken(): void {
    const token = localStorage.getItem("token");
    if (token && token !== "") {
      this.tokenSubject.next(token);
    }
}

fetchCalculosGeraisByDateList(data: CalculosGeraisByDate): Observable<CalculosGeraisByDateResponse[]> {
  return this.token$.pipe(
    switchMap(token => {
      const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      let params = new HttpParams();
      params = params.append('data_insert_inicial', decodeURIComponent(data.data_insert_inicial.toISOString().split('.')[0]));
      params = params.append('data_insert_final', decodeURIComponent(data.data_insert_final.toISOString().split('.')[0]));
      params = params.append('token', data.token);
      return this.http.get<CalculosGeraisByDateResponse[]>(this.apiService + 'GetCalculosGeraisByDate', { headers: header, params: params });
    })
  );
}


  fetchCalculosGeraisByDateSpecList(): Observable<CalculosGeraisByDateSpec> {
    return this.token$.pipe(
      switchMap(token => {
        const header = new HttpHeaders();
        return this.http.get<CalculosGeraisByDateSpec>(this.apiService, { headers: header });
      })
    );
  }
  fetchCalculosGeraisBySpecList(): Observable<CalculosGeraisBySpec> {
    return this.token$.pipe(
      switchMap(token => {
        const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get<CalculosGeraisBySpec>(this.apiService, { headers: header });
      })
    );
  }
  fetchAllSpecNamesList(): Observable<AllSpecNames> {
    return this.token$.pipe(
      switchMap(token => {
        const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get<AllSpecNames>(this.apiService, { headers: header });
      })
    );
  }


}
