import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Desp3 } from "../models/desp3";
import { BehaviorSubject, Observable, switchMap } from "rxjs";

@Injectable({
    providedIn: 'root'
  })

  export class Desp3Service{
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public token$: Observable<string> = this.tokenSubject.asObservable();
    public token: string = 'a';
    public apiService: string = environment.urlSnape + '/api/ExcelHandler/';

    constructor(private http: HttpClient) {
      this.initializeToken();
      }

      private initializeToken(): void {
        const storedAuth = localStorage.getItem("auth");
        if (storedAuth && storedAuth !== "") {
          const token = JSON.parse(storedAuth).token;
          this.tokenSubject.next(token);
        }
      }

      fetchDesp3List(): Observable<Desp3> {
        return this.token$.pipe(
          switchMap(token => {
            const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            return this.http.get<Desp3>(this.apiService, { headers: header });
          })
        );
      }
      async postDesp3List(data: Desp3[]) {
        const header = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
        const url = `${this.apiService}InsertDespThree?token=${this.token}`;
        return this.http.post<string>(url, data, { headers: header });
      }
  }