import { Component } from '@angular/core';

@Component({
  selector: 'app-dash-projection',
  templateUrl: './dash-projection.component.html',
  styleUrls: ['./dash-projection.component.css']
})
export class DashProjectionComponent {

}
