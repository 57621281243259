import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculosGeraisByDateResponse } from 'src/app/shared/models/calculosGeraisByDate';
import { CalculosGeraisService } from 'src/app/shared/services/calculosGerais';

@Component({
  selector: 'app-calculos-gerais',
  templateUrl: './calculos-gerais.component.html',
  styleUrls: ['./calculos-gerais.component.scss']
})
export class CalculosGeraisComponent implements OnInit {
  dateForm: FormGroup;
  dadosTabela: CalculosGeraisByDateResponse[];

  constructor(private fb: FormBuilder, private calculosGeraisService: CalculosGeraisService) { }

  ngOnInit(): void {
    this.dateForm = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required]
    });
  }

  onSubmit(): void {
    const dates = this.dateForm.value;
    const token = 'a';
    const data_insert_inicial = new Date(dates.start);
    const data_insert_final = new Date(dates.end);
    this.calculosGeraisService.fetchCalculosGeraisByDateList({data_insert_inicial: data_insert_inicial, data_insert_final: data_insert_final, token: token}).subscribe(data => {
      this.dadosTabela = data;

      console.log(data)
    });
  }
}
