import { RouterModule, Routes } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DashDesp3Component } from './dashboard/view/dash-desp3/dash-desp3.component';
import { DashProjectionComponent } from './dashboard/view/dash-projection/dash-projection.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './dashboard/view/home/home.component';
import { LoginComponent } from './login/login.component';
import { CalculosGeraisComponent } from './dashboard/view/calculos-gerais/calculos-gerais.component';
import { CalculoProvisaoComponent } from './dashboard/view/calculo-provisao/calculo-provisao.component';
import { AdminComponent } from './dashboard/view/admin/admin.component';

export const routes: Routes = [
    { path: 'login', component: LoginComponent }, // Adicione esta linha
    { path: 'home', component: HomeComponent },
    { path: 'dashdesp3', component: DashDesp3Component },
    { path: 'projection', component: DashProjectionComponent },
    { path: 'calculos-gerais', component: CalculosGeraisComponent },
    { path: 'calculos-provisao', component: CalculoProvisaoComponent },
    { path: 'admin', component: AdminComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' }, // Redireciona para a tela de login se a rota for vazia
];

@NgModule({
    declarations:[
        DashDesp3Component,
        DashProjectionComponent,
        HomeComponent,
    ],
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
        HttpClientModule,

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
  export class   AppRoutingModule {}
