import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { Desp5 } from "../models/desp5";

@Injectable({
    providedIn: 'root'
})

export class Desp5Service{
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public token$: Observable<string> = this.tokenSubject.asObservable();
  public token: string = 'a';
  public apiService: string = environment.urlSnape + '/api/ExcelHandler/';

  constructor(private http: HttpClient) {
      this.initializeToken();
  }

  private initializeToken(): void {
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth && storedAuth !== "") {
      const token = JSON.parse(storedAuth).token;
      this.tokenSubject.next(token);
    }
  }

  fetchDesp5List(): Observable<Desp5> {
    return this.token$.pipe(
      switchMap(token => {
        const header = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get<Desp5>(this.apiService, { headers: header });
      })
    );
  }

  async postDesp5List(data: Desp5[]) {
    const header = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    const url = `${this.apiService}InsertDespFive?token=${this.token}`;
    return this.http.post<string>(url, data, { headers: header });
  }
}
