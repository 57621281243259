<div class="demo-layout">
<tds-header>
  <tds-header-hamburger
  id="demo-hamburger"
  onclick="demoSideMenu.open = true;demoHamburger.setAttribute('aria-expanded', true);"
  aria-label="Open application drawer"
  aria-haspopup="true"
  aria-expanded="false"
></tds-header-hamburger>
    <tds-header-item >
      <button (click)="navigateToHome()"> SNAPE</button>
    </tds-header-item>
    <tds-header-item >
      <button (click)="navigateToDashDesp3()">Data Flow</button>
    </tds-header-item>
    <tds-header-item >
      <a>Projeção</a>
    </tds-header-item>
    <tds-header-dropdown>
      <span slot="label">Book Closing</span>
      <tds-header-dropdown-list>
        <tds-header-dropdown-list-item>
          <button (click)="navigateToCalculoProvisao()">Calculo de Provisão</button>
        </tds-header-dropdown-list-item>
        <tds-header-dropdown-list-item>
          <button (click)="navigateToCalculosGerais()">Book Closing LOM</button>
        </tds-header-dropdown-list-item>
      </tds-header-dropdown-list>
    </tds-header-dropdown>
    <!-- <tds-header-item>
      <a>Demuarge</a>
    </tds-header-item> -->

    <tds-header-launcher slot="end">
      <tds-header-launcher-list-title>Tools</tds-header-launcher-list-title>
      <tds-header-launcher-list>
        <tds-header-launcher-list-item>
          <button (click)="navigateToAdmin()">Admin</button>
        </tds-header-launcher-list-item>
      </tds-header-launcher-list>
    </tds-header-launcher>
  
    <tds-header-brand-symbol slot="end">
      <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
    </tds-header-brand-symbol>
  </tds-header>
</div>