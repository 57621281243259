import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TegelModule } from '@scania/tegel-angular';
import { AppComponent } from './app.component';
import { NavbarComponent } from './dashboard/components/navbar/navbar.component';
import { SidebarComponent } from './dashboard/components/sidebar/sidebar.component';
import { FooterComponent } from './dashboard/components/footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app.routes';
import { MainContentComponent } from './dashboard/view/main-content/main-content.component';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoginViewComponent } from './login/view/login-view/login-view.component';
import { LoginModule } from './login/login.module';
import { CalculosGeraisComponent } from './dashboard/view/calculos-gerais/calculos-gerais.component';
import { AdminComponent } from './dashboard/view/admin/admin.component';
import { CalculoProvisaoComponent } from './dashboard/view/calculo-provisao/calculo-provisao.component';
import { ReactiveFormsModule } from '@angular/forms';

const ROUTES: Routes = [
 //{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'dashboard', loadChildren: () => import('./app.routes').then(m => m.AppRoutingModule) },
  { path: '**', redirectTo: 'login' },
]

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    MainContentComponent,
    CalculosGeraisComponent,
    AdminComponent,
    CalculoProvisaoComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
    AppRoutingModule,
    HttpClientModule,
    TegelModule,
    LoginModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide: LocationStrategy,useClass:PathLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}