import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Login } from 'src/app/shared/models/login';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public isLoading: boolean = false;

  constructor(private loginService: LoginService, private router: Router) {}

  ngOnInit(): void {
    this.searchFormInit();
  }

  ngAfterViewInit(): void {
    const signupButton = document.getElementById('signup-button'),
    loginButton = document.getElementById('login-button'),
    userForms = document.getElementById('user_options-forms')

    signupButton.addEventListener('click', () => {
      userForms.classList.remove('bounceRight')
      userForms.classList.add('bounceLeft')
    }, false)

    loginButton.addEventListener('click', () => {
      userForms.classList.remove('bounceLeft')
      userForms.classList.add('bounceRight')
    }, false)
  }

  logIn(){
    let user = new Login();
    user.username = this.form.get('username').value;
    user.password = this.form.get('password').value;
  
    this.loginService.Authenticate(user).subscribe({
      next: (data) => {
        localStorage.setItem("auth", JSON.stringify(data));
      },
      error: (err) => {
        let message = (err.message.includes('Unauthorized') ? 'Username or Password does not match' : err.message)
        alert(message);
      },
      complete: () => {
        //alert("Success! You will be redirected to the main page");
        this.isLoading = true;
        setTimeout(() => {
          this.router.navigateByUrl('/home');
        }, 300);
      }
    });
  }
  
  searchFormInit() {
    this.form = new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
    });
  }

  
}
