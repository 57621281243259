<div>
    <h1 class="tds-headline-01">Admin</h1>
    <div style="width: 90%; margin-left: 2%;">
      <tds-divider orientation="horizontal"></tds-divider>
    </div>
</div>
<div>
    <tds-table class="table"
  table-id="pagination-table"
  vertical-dividers="false"
  compact-design="false"
  responsive="true"
>
  <tds-table-header>
    <tds-header-cell cell-key="truck" cell-value="Truck type"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Driver name"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Country"></tds-header-cell>
    <tds-header-cell cell-key="mileage" cell-value="Mileage" text-align="right"></tds-header-cell>
  </tds-table-header>
  <tds-table-body>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
  </tds-table-body>
  <tds-table-footer pages="4" pagination></tds-table-footer>
</tds-table>
</div>
