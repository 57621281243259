import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  template: `<app-login-view></app-login-view>`,
  styles: []
})
export class LoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}