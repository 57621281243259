<!-- <div class="demo-wrapper" >
  <tds-card header="Desp 3" image-placement="below-header" subheader="ImportSys">

    <div slot="body">
      <div style="overflow-x: auto;">
        
      <tds-table
  table-id="tds-table-sorting-example"
  vertical-dividers="false"
  compact-design="false"
  responsive="false"
>
  <tds-table-header>
    <tds-header-cell cell-key="truck" cell-value="Cod Processo" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Data Conhecimento" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Tipo Conhecimento" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="truck" cell-value="Desc Fornecedor" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Origem" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Tipo" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="truck" cell-value="Valor Desp Proc" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Cod Moeda" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Credor" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="truck" cell-value="Unidade" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Peças" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Fatura" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="truck" cell-value="Fabricante" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="driver" cell-value="Cod ContaContabel" sortable="true"></tds-header-cell>
    <tds-header-cell cell-key="country" cell-value="Qtde Peca" sortable="true"></tds-header-cell>
  </tds-table-header>
  <tds-table-body>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 1" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 2" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 3" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 4" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
    <tds-table-body-row>
      <tds-body-cell cell-value="Test value 5" cell-key="truck"></tds-body-cell>
      <tds-body-cell cell-value="Test value 6" cell-key="driver"></tds-body-cell>
      <tds-body-cell cell-value="Test value 7" cell-key="country"></tds-body-cell>
      <tds-body-cell cell-value="Test value 8" cell-key="mileage"></tds-body-cell>
    </tds-table-body-row>
  </tds-table-body>
</tds-table>
    </div>
  </div>
  </tds-card>
</div> -->
<div>
  <h1 class="tds-headline-01">DATA FLOW</h1>
  <div style="width: 90%; margin-left: 2%;">
    <tds-divider orientation="horizontal"></tds-divider>
  </div>
</div>
<div class="demo">
  <div class="desp3">
    <tds-card header="Desp3"  image-placement="above-header" subheader="Subheader text" body-img="assets/teste1.jpeg">
      <div slot="body">
        <div class="demo-wrapper">
          <input type="file" (change)="onFileChange($event)">
        </div>
        <tds-button (click)="uploadFile()" type="button" variant="primary" size="lg" text="Upload">
          <tds-icon slot="icon" size="20px" name="upload"></tds-icon>
        </tds-button>
      </div>
    </tds-card>
  </div>
  
  <div class="desp5">
    <tds-card header="Desp5" image-placement="above-header" subheader="Subheader text" body-img="assets/desp5.jpeg">
      <div slot="body">
        <div class="demo-wrapper">
          <input type="file" (change)="onFileChangeDesp5($event)">
        </div>
        <tds-button (click)="uploadFileDesp5()" type="button" variant="primary" size="lg" text="Upload">
          <tds-icon slot="icon" size="20px" name="upload"></tds-icon>
        </tds-button>
      </div>
    </tds-card>
  </div>
</div>


