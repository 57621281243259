import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from './shared/services/login.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  hideBanner = false
  mobileMenuOpen = false
  isLoggedIn$: Observable<boolean>; 

  constructor(private router: Router, private loginService: LoginService) {
    this.isLoggedIn$ = this.loginService.isLoggedIn();
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const mainContentEl = document.querySelector('main-content');
        mainContentEl && mainContentEl.scrollTo(0, 0);
      }
    });
  }

  handleEvent($event) {
    switch($event.type) {
      case "showMobileMenu":
        this.mobileMenuOpen = $event.value;
      break;
    }
  }

  onCloseBanner() {
    this.hideBanner = true;
    
    // hack to refresh tooltips positions
    window.scrollTo(0, 1);
  }
}