import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login.component";
import { LoginViewComponent } from "./view/login-view/login-view.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginService } from "../shared/services/login.service";
import { WrapperComponent } from './components/wrapper/wrapper/wrapper.component';

const ROUTES: Routes = [
  {
    path: '', component: LoginComponent, children: [
      { path: '', component: LoginViewComponent },
    ]
  }
  ]

  @NgModule({
    declarations: [
      LoginComponent,
      LoginViewComponent,
      WrapperComponent
    ],
    imports: [
      CommonModule,
      RouterModule.forChild(ROUTES),
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
    ],
    providers: [LoginService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
  export class LoginModule { }