import { Component,} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  constructor(private router: Router) { }
  navigateToDashDesp3(): void {
    this.router.navigate(['/dashdesp3']);
      console.log('carregado');
  }
  navigateToHome(): void {
    this.router.navigate(['']);
  }

  navigateToCalculosGerais(): void {
    this.router.navigate(['/calculos-gerais']);
  }

  navigateToCalculoProvisao(): void {
    this.router.navigate(['/calculos-provisao']);
  }

  navigateToAdmin(): void {
    this.router.navigate(['/admin']);
  }
  


}