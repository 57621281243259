import { Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent{

  constructor(private router: Router) { }

  navigateToDashDesp3(): void {
    this.router.navigate(['/dashdesp3']);

   
      console.log('carregado');
    
  }
  navigateToDashDesp5(): void {
    this.router.navigate(['/dashdesp5']);
    console.log('carregado');
  }
  navigateToProjection(): void {
    this.router.navigate(['/projection']);

    console.log('carregado');
  }

}
