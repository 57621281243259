<div class="demo-layout">
    <div class="demo-wrap-side-menu-and-main">
      <tds-side-menu aria-label="Side menu" id="demo-side-menu" persistent>
        <tds-side-menu-overlay
          slot="overlay"
          onclick="demoSideMenu.open = true;"
        ></tds-side-menu-overlay>
        <div>
          <tds-side-menu-item default-open selected>
            <button (click)="navigateToDashDesp3()">
              <tds-icon name="document_check" size="24px"></tds-icon>
              Data Flow
            </button>
          </tds-side-menu-item>
        </div>

        <div>
          <tds-side-menu-item>
            <button (click)="navigateToProjection()">
              <tds-icon name="report" size="24px"></tds-icon>
              Projection
            </button>
          </tds-side-menu-item>
        </div>

  
        <tds-side-menu-collapse-button slot="sticky-end"></tds-side-menu-collapse-button>
      </tds-side-menu>
    </div>
  </div>